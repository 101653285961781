function formatCategory(category) {
  if (!category.id) { return category.text }

  var $category = $(
    '<span class="ml-' + category.depth * 3 + '">' + category.text + '</span>'
  )
  return $category
};

function initQuestionCategorySelector(selector) {
  var selector = typeof (selector) == "undefined" ? "select#question_context_key_id" : selector
  var element = $(selector)

  element.select2({
    templateResult: formatCategory,
    placeholder: "Выберите категорию",
    width: '100%',
    data: prepareDataForQuestionCategory(element.data("id"))
  })
}

function prepareDataForQuestionCategory(id) {
  var data = $.map(gon.categories_for_questions, function (obj) {
    if (id == obj.id.toString()) {
      obj.selected = true
    }

    return obj
  })
  return data
}


function validatePhone(el) {
  var form = $(el).closest("form")
  $(form).validate({
    rules: {
      "question[phone]": {
        phonelength: true
      }
    },
    errorClass: "form-error",
    errorElement: "span",
    highlight: function (element, errorClass, validClass) {
      if (element.type === "radio") {
        this.findByName(element.name).addClass(errorClass).removeClass(validClass)
      } else {
        $(element).addClass(errorClass).removeClass(validClass)
      }
      $(element).tooltip("show")
    },
    unhighlight: function (element, errorClass, validClass) {
      if (element.type === "radio") {
        this.findByName(element.name).removeClass(errorClass).addClass(validClass)
      } else {
        $(element).removeClass(errorClass).addClass(validClass)
      }
      $(element).tooltip("hide")
    }
  })
}

function initPhoneValidations() {
  $(".phone-tooltip-validation").each(function () {
    $(this).prop("title", "Номер должен начинаться с 7 или 8 и содержать 11 цифр")

    validatePhone(this)

    $(this).tooltip({
      placement: "auto",
      trigger: "manual",
      template: '<div class="tooltip tooltip-error" role="tooltip">' + '<div class="arrow"></div>' + '<div class="tooltip-inner"></div></div>',
    })
  })
}


window.renderQuestionWithErrors = function (content) {
  $('#full-form').html(content)
  $('#short-form').hide()
  $('.alert-info').hide()
  $('.form-show-button').hide()
  $('.popup-form').hide()
  $('.mobile-form-show-button').attr('hidden', true)
  initCitySelector('#question_fias_id')
  initQuestionCategorySelector()
  initPhoneValidations()
  if ($('#full-form').length) {
    $(document).scrollTop($('#full-form').offset().top)
  }
}

window.renderQuestionErrorsOnUpdate = function (qid, content) {
  $('#question-' + qid).append(content)
}

window.removeQuestionOnUpdate = function (selector) {
  var question = $(selector).slideUp()
  setTimeout(function () {
    question.remove()
  }, 2000)
}

window.rerenderQuestionOnUpdate = function (qid, content) {
  $('#question-' + qid).replaceWith(content)
  initQuestionCategorySelector('#question-' + qid + ' select#question_context_key_id')
  $('#question-' + qid + '-errors').slideUp()
}

window.renderModal = function (content) {
  $(content).modal({
    backdrop: 'static',
    keyboard: false
  })
}