/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from 'rails-ujs';
Rails.start();

require('font-awesome/scss/font-awesome.scss');
require('bootstrap/scss/bootstrap.scss');
require('select2/dist/css/select2.css');
require('./styles/old_landing.scss');
require('./styles/question_popup.scss');
require('./styles/questions.scss');
require('./styles/select2.scss');
require('./styles/tooltip.scss');
require('../styles/call_us.css');
require('../styles/yoomoney.css');

require('bootstrap/dist/js/bootstrap');

require('../custom/jquery.validate');
require('../custom/phone_validation');
require("../custom/question_create_modal");
require('../custom/disengageable_btn');
require('../custom/tracking');
require('../custom/tooltip');
require('../custom/sticky_sidebar');
require('../custom/track_phone');
require('../custom/select2');
require('../custom/question_old');
require('../custom/show_phone');
require('../custom/transaction_record');
require('./scripts/popup_window');
