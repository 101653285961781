window.initCitySelector = function(selector, width, onselect) {
  var initials = [];

  gon.cached_cities.forEach(function (item, i) {
    initials.push({
      id: item.fias_id,
      text: item.name
    });
  });

  $(selector).select2({
    placeholder: "Выберите город",
    width: typeof (width) == "undefined" ? "100%" : width,
    ajax: {
      url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
      type: "post",
      dataType: 'json',
      delay: 250,
      contentType: "application/json",
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Token 463308d2f3c20a6bf6bc63f1339dcbcdbb9ad1b6");
      },
      transport: function (params, success, failure) {
        if (params.data.length > 65) {
          var $request = $.ajax(params);

          $request.then(success);
          $request.fail(failure);

          return $request;
        } else {
          return success({
            results: initials
          });
        }
      },
      data: function (params) {
        return JSON.stringify({
          query: params.term,
          from_bound: {value: "city"},
          to_bound: {value: "settlement"}
        });
      },
      processResults: function (data, params) {
        var suggestions = [];
        if (data.suggestions != undefined) {
          for (var i = 0; i < data.suggestions.length; i++) {
            suggestions.push({
              id: data.suggestions[i].data.fias_id,
              text: data.suggestions[i].value
            })
          }
        }
        if (suggestions.length == 0) {
          return {
            results: initials
          };
        } else {
          return {
            results: suggestions
          };
        }
      },
      cache: true
    },
    language: {
      noResults: function () {
        return 'Совпадений не найдено';
      },
      searching: function () {
        return 'Поиск…';
      },
      inputTooShort: function () {
        return "Начните вводить название города";
      }
    }
  });

  // Making a separate callback due to 2 packs not being able to access each other events
  if(typeof(onselect) == 'function') {
    $(selector).on('select2:select', function(e) { onselect(e) });
  }

  $('#city_id').select2({
    placeholder: "Выберите город",
    width: typeof (width) == "undefined" ? "100%" : width
  });
}

initCitySelector('#user_fias_id, #question_fias_id, #recall_full_form_question_fias_id, .context-campaign-city select, .city_id_for_yandex_keys');
